<template>
  <v-main style="background-color: #f7f7fd; padding-top: 52px">
    <HeaderFilesUpload ref="headerWss" />

    <v-card class="d-flex flex-column" id="cardDash" elevation="0">
      <v-col style="padding: 0px !important"> <CardNewFile></CardNewFile> </v-col>
    </v-card>
  </v-main>
</template>
<script>
import HeaderFilesUpload from './components/HeaderFilesUpload.vue';
import CardNewFile from './components/CardAddNewFile.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Wss',
  components: { HeaderFilesUpload, CardNewFile },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },

  async created() {
    // this.init(this.userInfo.id);
  },
  methods: {
    ...mapActions('documents', ['init']),
  },
};
</script>
<style scoped>
#subtitle {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #575767 !important;
  font-weight: regular !important;
  text-align: left !important;
}
#cardDash {
  width: 800px;
  align-items: center;
  align-self: center;
  align-content: center;
  /* padding: 16px 16px 16px 16px; */
  border: 1px solid #e7e7fa !important;
  border-radius: 4px !important;
  background-color: #ffffff;
}
#cardGraphFilter {
  padding: 16px 16px 1px;
}
</style>
<style>
.v-application.v-application--is-ltr.theme--light {
  background-color: #f7f7fd !important;
  color: var(--v-info-base) !important;
}
</style>

<template>
  <v-card id="new-rule-modal">
    <v-card-title>
      <v-row>
        <v-col cols="11"
          ><span style="font-size: 20px; font-weight: bold; color: #182026">Cadastrar novo documento</span></v-col
        >
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-col cols="12">
        <v-text-field
          id="term-field"
          v-model="params.name"
          :disabled="loading"
          :style="'color: var(--v-primary-base);'"
          maxlength="60"
          label="Nome do arquivo"
          rows="4"
          row-height="30"
          :counter="60"
          minlength="1"
          dense
          outlined
          @input="habilitarCampos"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          id="term-field"
          v-model="params.description"
          maxlength="500"
          label="Observação"
          rows="4"
          row-height="30"
          :disabled="loading"
          :style="'color: var(--v-primary-base);'"
          :counter="500"
          minlength="1"
          dense
          outlined
          @input="habilitarCampos"
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          id="cnpjs-field"
          v-model="params.cnpj"
          item-text="name"
          item-value="cnpj"
          label="Empresa"
          dense
          outlined
          multiple
          hide-details
          :disabled="loading"
          :items="userEstablishment"
          :style="'height:48px!important;max-height:48px!important;color: var(--v-primary-base);'"
          @change="habilitarCampos"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 0">
              {{ `${params.cnpj.length} selecionado${params.cnpj.length > 1 ? 's' : ''}` }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col sm="12" class="px-4 pb-0">
        <span>Tamanho máximo do arquivo é 500MB. Os tipos de arquivos suportados são XLS, DOC, ZIP e pdf </span>
      </v-col>
      <v-row>
        <v-col cols="4">
          <v-btn id="btnFilePDF" class="ml-3" sm="6" @click="uploadPDF">
            <span class="material-symbols-outlined"> drive_folder_upload </span>Adicionar arquivo *
          </v-btn>
          <input
            id="fileInput"
            ref="pdfInput"
            type="file"
            :disabled="loading"
            accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            style="display: none"
            @change="handleFileUpload"
          />
        </v-col>

        <v-col cols="6">
          <div v-if="pdfAdded" sm="6" class="btnFilePDFName">
            <span>{{ params.pdf.name }} <v-icon @click="removePDF()">mdi-close</v-icon></span>
          </div>
        </v-col>
      </v-row>
      <v-alert v-model="fileSizeError" dismissible dense text type="error"
        >O tamanho do arquivo supera o máximo permitido de 500mb</v-alert
      >
      <v-alert v-model="camposObrigatorios" dismissible dense text type="error">Preencha todos os campos</v-alert>
      <v-alert v-model="fileTypeError" dismissible dense text type="error">O tipo de arquivo não é permitido.</v-alert>
      <v-row class="mx-0">
        <v-col cols="10">
          <v-text-field
            id="idText"
            v-model="currentEmail"
            label="E-mail do destinatário"
            multiple
            dense
            outlined
            clearable
            :hint="`${totalEmails} /10 destinatários`"
            persistent-hint
            :style="'height:48px!important;max-height:48px!important;color: var(--v-primary-base);'"
          >
          </v-text-field>
        </v-col>

        <v-col cols="2">
          <v-btn id="add-email-btn" @click="addEmail">
            <v-icon x-large>mdi-plus</v-icon>
          </v-btn></v-col
        >
      </v-row>

      <div class="emails-list">
        <v-list v-for="(email, i) in params.emails" :key="i">
          <v-list-item id="email-item">
            <v-list-item-title id="email-title" :style="'background-color:#F7F7FD;'"
              ><p :style="'font-size: 14px !important;line-height:18.62px!important'">
                {{ email }}
              </p></v-list-item-title
            >
            <button id="email-remove" :style="'background-color:#F7F7FD'" @click="removeEmail(i)">
              <v-icon x-small color="success">mdi-close</v-icon>
            </button>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />

      <v-btn id="save-btn" color="success" :loading="loading" :disabled="!valid || loading" @click="save()">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { eventBus } from '@/main.js';
import AdminServices from '@/services/adminServices.js';
import Documents from '@/services/interactionService.js';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      userEstablishment: [],
      pdfAdded: false,
      loading: false,
      totalEmails: 0,
      currentEmail: null,
      camposObrigatorios: false,
      valid: false,
      fileSizeError: false,
      fileTypeError: false,
      params: {
        report: false,
        cnpj: null,
        description: null,
        emails: [],
      },

      show: false,
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      userInfo: 'user/userInfo',
    }),
  },

  created() {
    this.getCompanies();
  },

  methods: {
    uploadPDF() {
      this.$refs.pdfInput.click();
    },

    removePDF() {
      if (!this.loading) {
        this.pdfAdded = false;
        this.params.pdf = null;
      }
    },

    handleFileUpload(event) {
      const input = document.getElementById('fileInput');

      this.fileSizeError = false;
      if (this.checkFile(event.target.files[0]) && this.checkSize(event.target.files[0].size)) {
        this.fileSizeError = false;
        this.fileTypeError = false;
        this.params.pdf = event.target.files[0];
        this.pdfAdded = true;
        this.habilitarCampos();
      } else {
        input.value = '';
        this.fileSizeError = true;
      }
    },

    checkSize(file) {
      if (file <= 500000001) {
        this.fileSizeError = false;
        return true;
      } else {
        this.fileSizeError = true;
        return false;
      }
    },

    checkFile(type) {
      var name = this.getExtension(type.name);
      this.fileTypeError = false;
      if (name == 'pdf' || name == 'xls' || name == 'xlsx' || name == 'doc' || name == 'docx' || name == 'zip') {
        this.fileTypeError = false;
        return true;
      } else {
        this.fileTypeError = true;
        false;
      }
    },

    getExtension(filename) {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    },

    addEmail() {
      if (this.currentEmail && this.params.emails.length < 10) {
        this.params.emails.push(this.currentEmail);
        this.totalEmails++;
      }
      this.habilitarCampos();
    },

    removeEmail(index) {
      if (this.params.emails.length > 0 && !this.loading) {
        this.params.emails.splice(index, 1);
        this.totalEmails--;
      }
      this.habilitarCampos();
    },

    async save() {
      if (this.params.emails.length < 1 || !this.params.cnpj || !this.params.description || !this.params.name) {
        this.camposObrigatorios = true;
        return;
      }

      this.loading = true;

      let file = this.mountFile(this.params);

      await Documents.files.upload(file);

      const input = document.getElementById('fileInput');

      this.params.name = '';
      this.params.description = '';
      this.params.pdf = '';
      this.params.cnpj = [];
      this.params.emails = [];
      this.pdfAdded = false;
      this.currentEmail = '';
      input.value = null;

      this.showToast('Documento cadastrado com sucesso!', 'success', 78);
      this.loading = false;

      // this.setParams();
    },

    mountFile(informationForFile) {
      let file = new FormData();
      file.file = informationForFile.pdf;
      file.append('file', informationForFile.pdf);
      let date = new Date();
      file.append('fileName', informationForFile.name + '.' + this.getExtension(informationForFile.pdf.name));
      file.append('description', informationForFile.description);
      file.append('uploadedAt', date.getTime());
      file.append('report', 'false');
      file.append('companies', informationForFile.cnpj);
      file.append('emails', informationForFile.emails);
      return file;
    },

    async getCompanies() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);
      let userEstablishments = data.map((item) => {
        return { name: item.establishment.socialName, cnpj: item.establishment.details.cnpj.substring(0, 8) };
      });
      userEstablishments = sortBy(userEstablishments, ['name']);
      let establishments = userEstablishments.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
      this.userEstablishment = establishments;
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    habilitarCampos() {
      this.camposObrigatorios = false;
      this.valid =
        this.params.name &&
        this.params.cnpj?.length >= 1 &&
        this.params.description &&
        this.params.emails?.length >= 1 &&
        (this.params.pdf != null || this.params.pdf != undefined);
    },
  },
};
</script>
<style>
#new-rule-modal {
  align-items: center;
  align-self: center;
  align-content: center;
  width: 800px;
  background-color: white;
}
#term-field {
  color: var(--v-primary-base);
}
#add-email-btn {
  background-color: white;
  color: var(--v-primary-base);
  height: 48px !important;
}
#add-email-btn >>> .theme--dark.v-btn.v-btn--has-bg {
  background-color: white;
}
#email-title {
  background-color: #cdd0ed;
  padding: 4px;
  border-radius: 12px;
  margin-right: -4%;
}
#email-title p {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}
#email-remove {
  background-color: #cdd0ed;
  border-radius: 92%;
  margin-right: 46%;
}
#idText {
  color: var(--v-primary-base);
}

.v-messages__message {
  /* display: none; */
  background: none !important;
  margin-left: -15px;
  text-align: start;
}
.v-text-field__slot {
  color: var(--v-primary-base);
}
#btnSalvar:disabled {
  background-color: #e7e7fa !important;
}
.v-label {
  color: var(--v-primary-base);
}
.v-label .input {
  color: var(--v-primary-base);
}
.v-select__selections .v-select__selection--comma {
  color: var(--v-primary-base);
}

#new-rule-modal label {
  color: var(--v-primary-base);
}

#new-rule-modal input {
  color: var(--v-primary-base);
}

#new-rule-modal textarea {
  color: var(--v-primary-base);
}

#cancel-btn :hover {
  background-color: #e3ffee !important;
  padding: 15% 25%;
  max-height: 90px;
  border-radius: 5px;
}

#save-btn :hover {
  background-color: #e3ffee !important;
  padding: 22% 39%;
  max-height: 90px;
  border-radius: 5px;
  color: var(--v-primary-base);
}
</style>

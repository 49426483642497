var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticStyle: { "background-color": "#f7f7fd", "padding-top": "52px" } },
    [
      _c("HeaderFilesUpload", { ref: "headerWss" }),
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column",
          attrs: { id: "cardDash", elevation: "0" },
        },
        [
          _c(
            "v-col",
            { staticStyle: { padding: "0px !important" } },
            [_c("CardNewFile")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
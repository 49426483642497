var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { id: "new-rule-modal" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "11" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "20px",
                      "font-weight": "bold",
                      color: "#182026",
                    },
                  },
                  [_vm._v("Cadastrar novo documento")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                style: "color: var(--v-primary-base);",
                attrs: {
                  id: "term-field",
                  disabled: _vm.loading,
                  maxlength: "60",
                  label: "Nome do arquivo",
                  rows: "4",
                  "row-height": "30",
                  counter: 60,
                  minlength: "1",
                  dense: "",
                  outlined: "",
                },
                on: { input: _vm.habilitarCampos },
                model: {
                  value: _vm.params.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                style: "color: var(--v-primary-base);",
                attrs: {
                  id: "term-field",
                  maxlength: "500",
                  label: "Observação",
                  rows: "4",
                  "row-height": "30",
                  disabled: _vm.loading,
                  counter: 500,
                  minlength: "1",
                  dense: "",
                  outlined: "",
                },
                on: { input: _vm.habilitarCampos },
                model: {
                  value: _vm.params.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "description", $$v)
                  },
                  expression: "params.description",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-autocomplete", {
                style:
                  "height:48px!important;max-height:48px!important;color: var(--v-primary-base);",
                attrs: {
                  id: "cnpjs-field",
                  "item-text": "name",
                  "item-value": "cnpj",
                  label: "Empresa",
                  dense: "",
                  outlined: "",
                  multiple: "",
                  "hide-details": "",
                  disabled: _vm.loading,
                  items: _vm.userEstablishment,
                },
                on: { change: _vm.habilitarCampos },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ index }) {
                      return [
                        index === 0
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.params.cnpj.length} selecionado${
                                      _vm.params.cnpj.length > 1 ? "s" : ""
                                    }`
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.params.cnpj,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "cnpj", $$v)
                  },
                  expression: "params.cnpj",
                },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "px-4 pb-0", attrs: { sm: "12" } }, [
            _c("span", [
              _vm._v(
                "Tamanho máximo do arquivo é 500MB. Os tipos de arquivos suportados são XLS, DOC, ZIP e pdf "
              ),
            ]),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: { id: "btnFilePDF", sm: "6" },
                      on: { click: _vm.uploadPDF },
                    },
                    [
                      _c("span", { staticClass: "material-symbols-outlined" }, [
                        _vm._v(" drive_folder_upload "),
                      ]),
                      _vm._v("Adicionar arquivo * "),
                    ]
                  ),
                  _c("input", {
                    ref: "pdfInput",
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "fileInput",
                      type: "file",
                      disabled: _vm.loading,
                      accept:
                        "application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    },
                    on: { change: _vm.handleFileUpload },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "6" } }, [
                _vm.pdfAdded
                  ? _c(
                      "div",
                      { staticClass: "btnFilePDFName", attrs: { sm: "6" } },
                      [
                        _c(
                          "span",
                          [
                            _vm._v(_vm._s(_vm.params.pdf.name) + " "),
                            _c(
                              "v-icon",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removePDF()
                                  },
                                },
                              },
                              [_vm._v("mdi-close")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-alert",
            {
              attrs: { dismissible: "", dense: "", text: "", type: "error" },
              model: {
                value: _vm.fileSizeError,
                callback: function ($$v) {
                  _vm.fileSizeError = $$v
                },
                expression: "fileSizeError",
              },
            },
            [_vm._v("O tamanho do arquivo supera o máximo permitido de 500mb")]
          ),
          _c(
            "v-alert",
            {
              attrs: { dismissible: "", dense: "", text: "", type: "error" },
              model: {
                value: _vm.camposObrigatorios,
                callback: function ($$v) {
                  _vm.camposObrigatorios = $$v
                },
                expression: "camposObrigatorios",
              },
            },
            [_vm._v("Preencha todos os campos")]
          ),
          _c(
            "v-alert",
            {
              attrs: { dismissible: "", dense: "", text: "", type: "error" },
              model: {
                value: _vm.fileTypeError,
                callback: function ($$v) {
                  _vm.fileTypeError = $$v
                },
                expression: "fileTypeError",
              },
            },
            [_vm._v("O tipo de arquivo não é permitido.")]
          ),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("v-text-field", {
                    style:
                      "height:48px!important;max-height:48px!important;color: var(--v-primary-base);",
                    attrs: {
                      id: "idText",
                      label: "E-mail do destinatário",
                      multiple: "",
                      dense: "",
                      outlined: "",
                      clearable: "",
                      hint: `${_vm.totalEmails} /10 destinatários`,
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.currentEmail,
                      callback: function ($$v) {
                        _vm.currentEmail = $$v
                      },
                      expression: "currentEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "add-email-btn" },
                      on: { click: _vm.addEmail },
                    },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "emails-list" },
            _vm._l(_vm.params.emails, function (email, i) {
              return _c(
                "v-list",
                { key: i },
                [
                  _c(
                    "v-list-item",
                    { attrs: { id: "email-item" } },
                    [
                      _c(
                        "v-list-item-title",
                        {
                          style: "background-color:#F7F7FD;",
                          attrs: { id: "email-title" },
                        },
                        [
                          _c(
                            "p",
                            {
                              style:
                                "font-size: 14px !important;line-height:18.62px!important",
                            },
                            [_vm._v(" " + _vm._s(email) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          style: "background-color:#F7F7FD",
                          attrs: { id: "email-remove" },
                          on: {
                            click: function ($event) {
                              return _vm.removeEmail(i)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { "x-small": "", color: "success" } },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                id: "save-btn",
                color: "success",
                loading: _vm.loading,
                disabled: !_vm.valid || _vm.loading,
              },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }